<template>
  <div class="mb-main-content" :class="compareView ? 'mb-compare-view' : ''">
    <div>
      <h1 class="mb-4" v-t="'page.statistics.title'"></h1>
      <b-alert show variant="warning">Die Statistiken sind aktuell leider nicht erreichbar. Wir arbeiten an einer technischen Lösung und werden diese Seite bald wieder aktivieren.</b-alert>
    </div>
  </div>
</template>

<script>
// import ImpressionsBarChart from '../components/charts/ImpressionsBar.vue'
// import TopBrandBarChart from '../components/charts/TopBrandBar.vue'
// import LeadsBarChart from '../components/charts/LeadsBar.vue'
// import LeadsPieChart from '../components/charts/LeadsPieChart.vue'
// import LeadsMap from '../components/charts/LeadsMap.vue'
// import StatisticMethods from '../modules/StatisticMethods'
// import moment from 'moment'
// import DatePicker from 'vue2-datepicker'

export default {
  components: {
    // DatePicker,
    // ImpressionsBarChart,
    // TopBrandBarChart,
    // LeadsBarChart,
    // LeadsPieChart,
    // LeadsMap
  },
  name: 'Statistics',
  props: [],
  data: function () {
    return {
      // dateRange: [],
      // momentFormat: {
      // // [optional] Date to String
      //   stringify: (date) => {
      //     return date ? moment(date).format('DD.MM.YYYY') : ''
      //   },
      //   // [optional]  String to Date
      //   parse: (value) => {
      //     return value ? moment(value, 'DD.MM.YYYY').toDate() : null
      //   }
      // },
      // datePickerShortcuts: [],
      // groupBy: 'Week',
      // pageIsLoaded: false,
      // compareView: false,
      // loadCounter: 0
    }
  },
  computed: {
    // currentBrand () {
    //   const current = this.app.currentBrand || {}
    //   return current
    // },
    // app: function () {
    //   return this.$root.getApp() || {}
    // },
    // startDate () {
    //   return moment(this.dateRange[0]).format('DD.MM.YYYY')
    // },
    // endDate () {
    //   return moment(this.dateRange[1]).format('DD.MM.YYYY')
    // },
    // lang () {
    //   return this.$i18n.locale !== 'de' ? 'en' : 'de'
    // },
    // SM () {
    //   if (_SM == null) _SM = new StatisticMethods(this.app.API)
    //   return _SM
    // }
  },
  created: function () {
    // const dateFrom = new Date()
    // dateFrom.setDate(dateFrom.getDate() - 29)
    // const dateTo = new Date()
    // this.dateRange = [
    //   dateFrom,
    //   dateTo
    // ]
    // this.datePickerShortcuts.push(this.getDatePickerShortcuts(this.$t('page.statistics.daterange.last7'), 6))
    // this.datePickerShortcuts.push(this.getDatePickerShortcuts(this.$t('page.statistics.daterange.last30'), 29))
    // this.datePickerShortcuts.push(this.getDatePickerShortcuts(this.$t('page.statistics.daterange.last90'), 89))
    // this.datePickerShortcuts.push(this.getDatePickerShortcuts(this.$t('page.statistics.daterange.last365'), 364))
    // this.pageIsLoaded = this.currentBrand.BrandID != null
  },
  methods: {
    // getDatePickerShortcuts (text, substractDays) {
    //   const obj =
    //   {
    //     text,
    //     onClick: () => {
    //       const dateFrom = new Date()
    //       dateFrom.setDate(dateFrom.getDate() - substractDays)
    //       const dateTo = new Date()

    //       return [dateFrom, dateTo]
    //     }
    //   }
    //   return obj
    // }
  },
  watch: {
    // 'currentBrand.BrandID': function () {
    //   this.pageIsLoaded = true
    // },
    // dateRange (newValue) {
    //   const startMmt = moment(newValue[0])
    //   const endMmt = moment(newValue[1])

    //   const monthsInRange = Math.abs(startMmt.diff(endMmt, 'month'))
    //   const weeksInRange = Math.abs(startMmt.diff(endMmt, 'week'))
    //   const daysInRange = Math.abs(startMmt.diff(endMmt, 'day'))

    //   if (this.groupBy === 'Month' && monthsInRange < 1) {
    //     if (weeksInRange > 0) {
    //       this.groupBy = 'Week'
    //     } else {
    //       this.groupBy = 'Day'
    //     }
    //   }

    //   if (this.groupBy === 'Week' && weeksInRange < 1) {
    //     this.groupBy = 'Day'
    //   }

    //   if (this.groupBy === 'Day' && daysInRange > 60) {
    //     this.groupBy = 'Week'
    //   }

    //   this.loadCounter++
    // },
    // groupBy (newValue) {
    //   this.loadCounter++
    // }
  },
  mounted: function () {
    // this.SM.getBrandBookings()
    // this.SM.getSpecialSectorGroups()
  }
}
</script>

<style lang="less">
</style>
