<template>
  <FairFieldEditor title="Konditionen: Was kostet es zu starten?" :index="11" :loading="isLoading" @save="handleSave"
    buttonSaveText="Änderungen speichern" :is-modal-open.sync="isModalOpen" @toggle="toggleContent"
    :isEditing="isEditing" @toggleEdit="toggleEdit" :saveDisabled="!computedIsValid" :usePageStore="true">
    <div class="quote-box">
      <div>
        <strong>Gesamtinvestition:</strong>
        {{ computedCondCaptions.CondInvestSumCaption }}
      </div>

      <div class="d-flex align-items-center gap-2 ps-4">
        <i class="fa fa-arrow-right"></i>
        <span><strong>Davon Eigenkapitalbedarf:</strong>
          {{ computedCondCaptions.CondRequiredCapitalCaption }}</span>
      </div>

      <div class="error d-flex align-items-center gap-2 ps-5" v-if="computedHasCondError.RequiredCapital">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M12 4L4 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M4 4L12 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span>darf das Minimum der Gesamtinvestition ({{
          computedCondInvestSumMinimum
          }}) <strong>nicht überschreiten</strong></span>
      </div>

      <div class="d-flex align-items-center gap-2 ps-4">
        <i class="fa fa-arrow-right"></i>
        <span><strong>Davon Eintrittsgebühr:</strong>
          {{ computedCondCaptions.CondEntryFeeCaption }}</span>
      </div>

      <div class="error d-flex align-items-center gap-2 ps-5" v-if="computedHasCondError.EntryFee">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M12 4L4 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M4 4L12 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span>darf das Minimum der Gesamtinvestition ({{
          computedCondInvestSumMinimum
          }}) <strong>nicht überschreiten</strong></span>
      </div>

      <div class="d-flex align-items-center gap-2 ps-4">
        <i class="fa fa-arrow-right"></i>
        <span><strong>Davon Erstausstattung:</strong>
          {{ computedCondCaptions.CondInitialCapitalCaption }}</span>
      </div>

      <div class="error d-flex align-items-center gap-2 ps-5" v-if="computedHasCondError.InitialCapital">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M12 4L4 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M4 4L12 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span>darf das Minimum der Gesamtinvestition ({{
          computedCondInvestSumMinimum
          }}) <strong>nicht überschreiten</strong></span>
      </div>
    </div>

    <hr />

    <div v-if="currentBrand.CondRequiredCapitalFrom > 0">
      Starte mit
      <strong>{{
        formatCurrency(currentBrand.CondRequiredCapitalFrom)
      }}
        Eigenkapital</strong>
      um weitere
      <strong>{{
        formatCurrency(
          currentBrand.CondInvestSumFrom -
          currentBrand.CondRequiredCapitalFrom
        )
      }}
        Fremdkapital</strong>
      aufzunehmen und die
      <strong>Gesamtinvestition von
        {{ formatCurrency(currentBrand.CondInvestSumFrom) }}</strong>
      zu erreichen und deinen Traum zu verwirklichen.
    </div>
    <div v-else>
      Starte mit
      {{ formatCurrency(currentBrand.CondRequiredCapitalFrom) }}
      Eigenkapital, um deinen Traum zu verwirklichen.
    </div>

    <div v-html="computedCondDescription"></div>

    <template v-slot:editButtonCaption>
      <div class="d-flex align-items-center gap-2">
        <i class="fa fa-pencil"></i>
        <span>Bearbeiten</span>
      </div>
    </template>
    <template v-slot:preview>
      <img class="img-fluid" src="@/assets/images/new-fair/4_Conditions.png" />
    </template>

    <template v-slot:edit>
      <div class="quote-box cond-box">
        <div class="d-flex flex-column gap-2 w-100">
          <span class="blue-headline">Wie hoch ist die Gesamtinvestitionssumme für eine Gründung mit
            {{ currentBrand.Name }}?</span>

          <div class="d-flex flex-sm-row flex-column justify-content-start align-items-center gap-3">
            <!-- input dropdown (von/bis; fix; ab) -->

            <b-form-select class="form-control flex-grow-0 w-100" v-model="formFields.CondInvestSumType"
              :options="computedCondFinanceTypes"></b-form-select>
            <hr class="vertical d-none d-sm-block" />

            <currency-input class="form-control flex-grow-0" v-model="formFields.CondInvestSumFrom" currency="EUR"
              locale="de" :allow-negative="false" required />
            <template v-if="formFields.CondInvestSumType === 1">
              <span>bis</span>
              <currency-input class="form-control flex-grow-0" v-model="formFields.CondInvestSumTo" currency="EUR"
                locale="de" :allow-negative="false" required />
            </template>
          </div>

          <hr />

          <div class="d-flex flex-column gap-3 ps-3">
            <div class="d-flex flex-column gap-2">
              <div class="d-flex align-items-center justify-content-start gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <path d="M3.3335 8.79639H12.6668" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M8 4.12964L12.6667 8.79631L8 13.463" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>

                <span class="blue-headline">Wievel davon muss als Eigenkapital vorhanden sein?</span>
              </div>

              <div class="d-flex flex-column flex-sm-row justify-content-start align-items-center gap-3" :style="{
                paddingLeft: '24px',
              }">
                <b-form-select class="form-control flex-grow-0" v-model="formFields.CondRequiredCapitalType"
                  :options="computedCondFinanceTypes"></b-form-select>
                <hr class="vertical d-none d-sm-block" />

                <currency-input class="form-control flex-grow-0" v-model="formFields.CondRequiredCapitalFrom"
                  currency="EUR" locale="de" :allow-negative="false" required />

                <template v-if="formFields.CondRequiredCapitalType === 1">
                  <span>bis</span>
                  <currency-input class="form-control flex-grow-0" v-model="formFields.CondRequiredCapitalTo"
                    currency="EUR" locale="de" :allow-negative="false" required />
                </template>
              </div>

              <div class="error d-flex align-items-center gap-2" v-if="computedHasCondErrorInEditForm.RequiredCapital"
                :style="{
                  paddingLeft: '24px',
                }">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M12 4L4 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M4 4L12 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
                <span>darf das Minimum der Gesamtinvestition ({{
                  computedCondInvestSumMinimumInEditForm
                  }}) <strong>nicht überschreiten</strong></span>
              </div>
            </div>

            <div class="d-flex flex-column gap-2">
              <div class="d-flex align-items-center justify-content-start gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <path d="M3.3335 8.79639H12.6668" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M8 4.12964L12.6667 8.79631L8 13.463" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>

                <span class="blue-headline">Wievel davon beträgt die Eintrittsgebühr?</span>
              </div>

              <div class="d-flex flex-column flex-sm-row justify-content-start align-items-center gap-3" :style="{
                paddingLeft: '24px',
              }">
                <b-form-select class="form-control flex-grow-0" v-model="formFields.CondEntryFeeType"
                  :options="computedCondFinanceTypes"></b-form-select>
                <hr class="vertical d-none d-sm-block" />

                <currency-input class="form-control flex-grow-0" v-model="formFields.CondEntryFeeFrom" currency="EUR"
                  locale="de" :allow-negative="false" required />

                <template v-if="formFields.CondEntryFeeType === 1">
                  <span>bis</span>
                  <currency-input class="form-control flex-grow-0" v-model="formFields.CondEntryFeeTo" currency="EUR"
                    locale="de" :allow-negative="false" required />
                </template>
              </div>

              <div class="error d-flex align-items-center gap-2" v-if="computedHasCondErrorInEditForm.EntryFee" :style="{
                paddingLeft: '24px',
              }">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M12 4L4 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M4 4L12 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
                <span>darf das Minimum der Gesamtinvestition ({{
                  computedCondInvestSumMinimumInEditForm
                  }}) <strong>nicht überschreiten</strong></span>
              </div>
            </div>

            <div class="d-flex flex-column gap-2">
              <div class="d-flex align-items-center justify-content-start gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <path d="M3.3335 8.79639H12.6668" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M8 4.12964L12.6667 8.79631L8 13.463" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>

                <span class="blue-headline">Wievel davon wird für die Erstausstattung benötigt?</span>
              </div>

              <div class="d-flex flex-column flex-sm-row justify-content-start align-items-center gap-3" :style="{
                paddingLeft: '24px',
              }">
                <b-form-select class="form-control flex-grow-0" v-model="formFields.CondInitialCapitalType"
                  :options="computedCondFinanceTypesInitialCapital"></b-form-select>
                <hr class="vertical d-none d-sm-block" v-if="computedShowSumFieldsForIniticalCapital" />

                <currency-input class="form-control flex-grow-0" v-if="computedShowSumFieldsForIniticalCapital"
                  v-model="formFields.CondInitialCapitalFrom" currency="EUR" locale="de" :allow-negative="false"
                  required />

                <template v-if="formFields.CondInitialCapitalType === 1">
                  <span>bis</span>
                  <currency-input class="form-control flex-grow-0" v-model="formFields.CondInitialCapitalTo"
                    currency="EUR" locale="de" :allow-negative="false" required />
                </template>
              </div>

              <div class="error d-flex align-items-center gap-2" v-if="computedHasCondErrorInEditForm.InitialCapital"
                :style="{
                  paddingLeft: '24px',
                }">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M12 4L4 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M4 4L12 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
                <span>darf das Minimum der Gesamtinvestition ({{
                  computedCondInvestSumMinimumInEditForm
                  }}) <strong>nicht überschreiten</strong></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="d-flex flex-column gap-3">
        <div class="d-flex flex-column gap-2">
          <span class="blue-headline">Daraus generiert sich am Messestand der folgende Text:</span>

          <div v-if="formFields.CondRequiredCapitalFrom > 0">
            Starte mit
            <strong>{{
              formatCurrency(formFields.CondRequiredCapitalFrom)
              }}
              Eigenkapital</strong>
            um weitere
            <strong>{{
              formatCurrency(
                formFields.CondInvestSumFrom -
                formFields.CondRequiredCapitalFrom
              )
            }}
              Fremdkapital</strong>
            aufzunehmen und die
            <strong>Gesamtinvestition von
              {{ formatCurrency(formFields.CondInvestSumFrom) }}</strong>
            zu erreichen und deinen Traum zu verwirklichen.
          </div>
          <div v-else>
            Starte mit
            {{ formatCurrency(formFields.CondRequiredCapitalFrom) }}
            Eigenkapital, um deinen Traum zu verwirklichen.
          </div>
        </div>

        <div class="d-flex flex-column gap-2">
          <span class="blue-headline">Hier können Sie individuelle Details hinzufügen:</span>

          <textarea class="form-control w-100" v-model="formFields.CondDescription" :style="{
            height: '140px',
            minHeight: '200px',
          }"></textarea>
        </div>
      </div>

      <hr />

      <div class="d-flex flex-column w-100 gap-3">
        <div class="d-flex flex-column gap-1">
          <span class="fw-bold">Anforderungen an die individuellen Details zu den
            Konditionen:</span>
          <ul class="special">
            <li :class="{
              success: formFields.CondDescription.length <= 500,
              error: formFields.CondDescription.length > 500,
            }">
              Die individuellen Details dürfen max. 500 Zeichen haben
            </li>
            <li>
              Der Text soll etwaige Besonderheiten beschreiben, die nicht durch
              die Standard-Angaben ersichtlich sind
            </li>
            <li>
              Der Texte sollte hohe Erstinvestitionen begründen bzw.
              "rechtfertigen", finanzielle Sicherheit bzw. ein geringes Risiko
              vermitteln oder besonders positive finanzielle Anreize
              hervorheben, z. B. “Keine Lizenzgebühren” o.ä.
            </li>
            <li>
              Der Text sollte positiv formuliert sein und darstellen, dass sich
              die Investition lohnen wird
            </li>
            <li class="d-flex align-items-start inner-list">
              <div class="d-flex flex-column align-items-start gap-1">
                <strong>Beispiel:</strong>
                <span>“Dank der relativ geringen Erstinvestition kannst du meistens
                  recht schnell deinen ersten Gewinn machen. Zudem verspricht
                  dir eine Franchisepartnerschaft mit
                  {{ currentBrand.Name }} eine überdurchschnittlich hohe
                  Umsatzrendite.”</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </FairFieldEditor>
</template>

<script>
import FairFieldEditor from '@/components/FairFieldEditor.vue'
import { CurrencyInput } from 'vue-currency-input'
import { useBrandStore } from '@/stores/brandStore'

export default {
  name: 'FairFormConditions',
  components: {
    FairFieldEditor,
    CurrencyInput
  },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      isEditing: false,
      isModalOpen: false,
      formFields: {
        CondInvestSumType: '',
        CondInvestSumFrom: '',
        CondInvestSumTo: '',

        CondEntryFeeType: '',
        CondEntryFeeFrom: '',
        CondEntryFeeTo: '',

        CondInitialCapitalType: '',
        CondInitialCapitalFrom: '',
        CondInitialCapitalTo: '',

        CondRequiredCapitalType: '',
        CondRequiredCapitalFrom: '',
        CondRequiredCapitalTo: '',

        CondDescription: ''
      }
    }
  },
  methods: {
    toggleEdit () {
      this.isEditing = !this.isEditing
    },
    toggleContent () {
      this.isOpen = !this.isOpen
    },
    async handleSave () {
      this.isLoading = true
      try {
        await this.submitForm()
        this.$toast.success('Änderungen erfolgreich gespeichert.')
        this.isModalOpen = false
        this.formFields.value = ''
      } catch (error) {
        console.error('Error at Saving', error)
        this.$toast.error(
          'Beim Speichern deiner Änderungen ist ein Fehler aufgetreten.'
        )
      } finally {
        this.isLoading = false
      }
    },
    async submitForm () {
      const brandStore = useBrandStore()
      await brandStore.updateCurrentBrand({
        CondInvestSumType: this.formFields.CondInvestSumType ?? null,
        CondInvestSumFrom: this.formFields.CondInvestSumFrom ?? null,
        CondInvestSumTo: this.formFields.CondInvestSumTo ?? null,
        CondEntryFeeType: this.formFields.CondEntryFeeType ?? null,
        CondEntryFeeFrom: this.formFields.CondEntryFeeFrom ?? null,
        CondEntryFeeTo: this.formFields.CondEntryFeeTo ?? null,
        CondInitialCapitalType: this.formFields.CondInitialCapitalType ?? null,
        CondInitialCapitalFrom: this.formFields.CondInitialCapitalFrom ?? null,
        CondInitialCapitalTo: this.formFields.CondInitialCapitalTo ?? null,
        CondRequiredCapitalType:
          this.formFields.CondRequiredCapitalType ?? null,
        CondRequiredCapitalFrom:
          this.formFields.CondRequiredCapitalFrom ?? null,
        CondRequiredCapitalTo: this.formFields.CondRequiredCapitalTo ?? null,
        CondDescription: this.formFields.CondDescription ?? null
      })
    },
    formatCondCaption (type, fromValue, toValue) {
      switch (type) {
        case 0:
          return 'Keine Angabe'
        case 1:
          return `${this.formatCurrency(fromValue)} bis ${this.formatCurrency(
            toValue
          )}`
        case 2:
          return this.formatCurrency(fromValue)
        case 3:
          return `ab ${this.formatCurrency(fromValue)}`
        default:
          return 'Keine Angabe'
      }
    }
  },
  computed: {
    computedTitle () {
      return `Erfahrungen: ${this.computedExpansionFormCaption}-Partner:innen berichten`
    },
    computedCondDescription () {
      return String(this.currentBrand.CondDescription ?? '')
        .replaceAll('\r\n', '<br />')
        .replaceAll('\n\n', '<br />')
        .replaceAll('\r', '<br />')
        .replaceAll('\n', '<br />')
    },
    computedIsValid () {
      const brandStore = useBrandStore()
      return brandStore.validateFinancialConditionsForBrand(this.formFields)
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentBrand
    },
    computedCondInvestSumMinimum () {
      return this.formatCurrency(this.currentBrand.CondInvestSumFrom)
    },
    computedCondInvestSumMinimumInEditForm () {
      return this.formatCurrency(this.formFields.CondInvestSumFrom)
    },
    computedCondCaptions () {
      return {
        CondInvestSumCaption: this.formatCondCaption(
          this.currentBrand.CondInvestSumType,
          this.currentBrand.CondInvestSumFrom,
          this.currentBrand.CondInvestSumTo
        ),
        CondEntryFeeCaption: this.formatCondCaption(
          this.currentBrand.CondEntryFeeType,
          this.currentBrand.CondEntryFeeFrom,
          this.currentBrand.CondEntryFeeTo
        ),
        CondInitialCapitalCaption: this.formatCondCaption(
          this.currentBrand.CondInitialCapitalType,
          this.currentBrand.CondInitialCapitalFrom,
          this.currentBrand.CondInitialCapitalTo
        ),
        CondRequiredCapitalCaption: this.formatCondCaption(
          this.currentBrand.CondRequiredCapitalType,
          this.currentBrand.CondRequiredCapitalFrom,
          this.currentBrand.CondRequiredCapitalTo
        )
      }
    },
    formatCurrency () {
      return (value) => {
        return (
          new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 0, // No decimal places for whole numbers
            maximumFractionDigits: 0 // No decimal places for whole numbers
          }).format(value) + ' €'
        )
      }
    },
    computedHasCondError () {
      return {
        RequiredCapital:
          this.currentBrand.CondInvestSumFrom <
          this.currentBrand.CondRequiredCapitalFrom,
        EntryFee:
          this.currentBrand.CondInvestSumFrom <
          this.currentBrand.CondEntryFeeFrom,
        InitialCapital:
          this.currentBrand.CondInvestSumFrom <
          this.currentBrand.CondInitialCapitalFrom
      }
    },
    computedHasCondErrorInEditForm () {
      return {
        RequiredCapital:
          this.formFields.CondInvestSumFrom <
          this.formFields.CondRequiredCapitalFrom,
        EntryFee:
          this.formFields.CondInvestSumFrom < this.formFields.CondEntryFeeFrom,
        InitialCapital:
          this.formFields.CondInvestSumFrom <
          this.formFields.CondInitialCapitalFrom
      }
    },
    computedCondInvestSumCaption () {
      switch (this.currentBrand.CondInvestSumType) {
        case 0: {
          return 'Keine Angabe'
        }
        case 1: {
          const from = this.formatCurrency(this.currentBrand.CondInvestSumFrom)
          const to = this.formatCurrency(this.currentBrand.CondInvestSumTo)
          return `${from} bis ${to}`
        }
        case 2: {
          return this.formatCurrency(this.currentBrand.CondInvestSumFrom)
        }
        case 3: {
          return `ab ${this.formatCurrency(
            this.currentBrand.CondInvestSumFrom
          )}`
        }
        default: {
          return 'Keine Angabe'
        }
      }
    },

    computedCondFinanceTypes () {
      return [
        { value: 1, text: 'von / bis' },
        { value: 2, text: 'fix' },
        { value: 3, text: 'ab' }
      ]
    },

    computedShowSumFieldsForIniticalCapital () {
      return this.formFields.CondInitialCapitalType !== -1
    },

    computedCondFinanceTypesInitialCapital () {
      return [
        ...this.computedCondFinanceTypes,
        { value: -1, text: 'Keine Kosten für Erstausstattung' }
      ]
    }
  },
  mounted: function () {
    this.formFields.CondInvestSumType = this.currentBrand.CondInvestSumType
    this.formFields.CondInvestSumFrom = this.currentBrand.CondInvestSumFrom
    this.formFields.CondInvestSumTo = this.currentBrand.CondInvestSumTo
    this.formFields.CondEntryFeeType = this.currentBrand.CondEntryFeeType
    this.formFields.CondEntryFeeFrom = this.currentBrand.CondEntryFeeFrom
    this.formFields.CondEntryFeeTo = this.currentBrand.CondEntryFeeTo
    this.formFields.CondInitialCapitalType =
      this.currentBrand.CondInitialCapitalType
    this.formFields.CondInitialCapitalFrom =
      this.currentBrand.CondInitialCapitalFrom
    this.formFields.CondInitialCapitalTo =
      this.currentBrand.CondInitialCapitalTo
    this.formFields.CondRequiredCapitalType =
      this.currentBrand.CondRequiredCapitalType
    this.formFields.CondRequiredCapitalFrom =
      this.currentBrand.CondRequiredCapitalFrom
    this.formFields.CondRequiredCapitalTo =
      this.currentBrand.CondRequiredCapitalTo
    this.formFields.CondDescription = this.currentBrand.CondDescription ?? ''
  }
}
</script>

<style lang="scss" scoped>
textarea {
  padding: 8px 12px;
}
</style>
