import Vue from 'vue'
import VueResource from 'vue-resource'
import Config from '../config/index'
import router from '../router'
import merge from 'deepmerge'
import axios from 'axios'
import ErrorReport from './ErrorReport'

const param = require('jquery-param')
const _ErrorReport = new ErrorReport()

Vue.use(VueResource)

Vue.http.options.root = Config.API_URL + Config.API_VERSION

// Add an "after" method to Vue.$http
Vue.http.interceptors.push(function (request, next) {
  next(response => {
    if (request.after) {
      request.after.call(this, response)
    }
  })
})

export default class Api extends Vue {
  activeCalls = 0
  apiUrl = Config.API_URL + Config.API_VERSION

  currentBrand = null
  currentContact = null

  constructor (apiUrl) {
    super()
    if (apiUrl) this.apiUrl = apiUrl
  }

  _call (method, action, data, headers) {
    Vue.http.options.root = this.apiUrl

    if (!headers) headers = {}
    if (method.toLowerCase() === 'get' || method.toLowerCase() === 'delete') {
      const qstrg = data === undefined ? '' : param(data)
      if (qstrg && qstrg !== '') { action += (action.indexOf('?') >= 0 ? '&' : '?') + param(data) }
      data = null
    } else if (headers['Content-Type'] === 'application/json') {
      data = JSON.stringify(data)
    }

    headers.Authorization = this.getToken()

    const httpArgs = {
      headers,
      before: () => {
        this.activeCalls++
      },
      after: () => {
        this.activeCalls--
      }
    }
    if (
      method.toLowerCase() === 'post' &&
      headers['Content-Type'] !== 'application/json'
    ) { httpArgs.emulateJSON = true }

    let promise
    switch (method.toLowerCase()) {
      case 'get':
        promise = Vue.http.get(action, httpArgs)
        break
      case 'post':
        promise = Vue.http.post(action, data, httpArgs)
        break
      case 'put':
        promise = Vue.http.put(action, data, httpArgs)
        break
      case 'delete':
        promise = Vue.http.delete(action, httpArgs)
        break
    }

    promise
      .then(response => {
        const res = this.isValidApiResponse(response, action, true)
        if (!res) {
          _ErrorReport.sendReport(this.getToken(), response, this.currentBrand, this.currentContact, { action, method, data: JSON.parse(data) })
        }
        try {
          if (this.currentBrand && this.currentBrand.BrandID && method.toLowerCase() !== 'get' && action.indexOf('cache/clear') < 0) {
            this._post(Config.FPDE_API_URL + 'cache/clear', { pattern: '_' + this.currentBrand.BrandID + '_' })
          }
        } catch (error) {
          console.error('Error clearing cache', error)
        }
        return response
      })
      .catch(err => {
        if (
          err.status === 403 &&
          router.currentRoute.fullPath !== '/login'
        ) {
          localStorage.removeItem('FPMB_Login')
          router.push('/login')
        } else {
          console.error(action, method, err.message)
          _ErrorReport.sendReport(this.getToken(), err, this.currentBrand, this.currentContact, { action, method, data: JSON.parse(data) })
        }
        console.error('API Error:', err)
      })
    return promise
  }

  setBrandContext (currentBrand, currentContact) {
    this.currentBrand = currentBrand
    this.currentContact = currentContact
  }

  isValidApiResponse (response, action, writeError = false) {
    const errMsg = Vue.Utils.getObjVal(response, 'body.Result.ErrMsg') || ''
    const error = Vue.Utils.getObjVal(response, 'body.Result.Error') || ''
    if (error !== 0 &&
       error !== '' &&
      errMsg !== 'OK' &&
      errMsg !== '') {
      if (writeError) console.error(errMsg, action)
      return false
    } else if (
      errMsg !== '' &&
      errMsg !== 'OK' &&
      errMsg !== 'No records selected.' &&
      errMsg !== 'No records found.'
    ) {
      if (writeError) console.error(errMsg)
      return true
    }

    return true
  }

  getToken () {
    const loginFromStorage = localStorage.getItem('FPMB_Login')
    const login = loginFromStorage ? JSON.parse(loginFromStorage) : {}
    if (login && login.token) {
      return 'Bearer ' + login.token
    } else {
      return ''
    }
  }

  _post (action, data) {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    return this._call('POST', action, data, headers)
  }

  _get (action, data) {
    const headers = {
      Accept: 'application/json'
    }
    return this._call('GET', action, data, headers)
  }

  _delete (action, data) {
    const headers = {
      Accept: 'application/json'
    }
    return this._call('DELETE', action, data, headers)
  }

  _put (action, data) {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    return this._call('PUT', action, data, headers)
  }

  convertFPgridToAPI (fpgridCfg) {
    const fpgridCfDefault = {
      columns: [],
      filters: [{}],
      allowEdit: true,
      allowAdd: true,
      allowClick: true,
      paging: {
        currentPage: 1,
        perPage: 15,
        totalRows: 0
      },
      orderby: null,
      orderdesc: false,
      FullSubEntities: false
    }
    if (!fpgridCfg) fpgridCfg = fpgridCfDefault
    const merged = merge(fpgridCfDefault, fpgridCfg)
    const apiCfg = {
      FullSubEntities: merged.FullSubEntities,
      ResFrom: 1 + merged.paging.perPage * (merged.paging.currentPage - 1),
      ResTo: merged.paging.perPage * merged.paging.currentPage,
      filter: merged.filters
        .filter(f => {
          return typeof f.key !== 'undefined' && f.key != null && f.condition != null
        })
        .map(f => {
          if (f.val && !f.value) f.value = f.val
          if (f.value && typeof f.value.getMonth === 'function') {
            const d = f.value
            f.value =
                d.getFullYear() +
                '-' +
                ('00' + (d.getMonth() + 1)).slice(-2) +
                '-' +
                ('00' + d.getDate()).slice(-2) +
                ' ' +
                ('00' + d.getHours()).slice(-2) +
                ':' +
                ('00' + d.getMinutes()).slice(-2) +
                ':' +
                ('00' + d.getSeconds()).slice(-2)
          }
          return {
            field: f.key.toLowerCase(),
            operator: f.condition,
            value: f.value
          }
        }),
      OrderBy: merged.orderby
        ? [{
            field: merged.orderby,
            sortOrder: merged.orderdesc ? 'desc' : 'asc'
          }]
        : null
    }
    return apiCfg
  }

  /** ****************************************************** */
  /** * Call-Functions to API ****************************** */
  /** ****************************************************** */

  /* Check credentials against server and redirect to dashboard page if positive */
  login (data) {
    const postData = {
      Username: String(data.username).trim(),
      Password: String(data.password).trim()
    }
    localStorage.removeItem('FPMB_Login')

    return new Promise((resolve, reject) => {
      const loginRoute = this.apiUrl + 'login/'
      const headers = {
        Authorization: this.getToken()
      }

      axios({
        url: loginRoute,
        headers,
        data: postData,
        method: 'POST'
      }).then(response => {
        if (response.status === 200 && response.data.Result.Token !== '') {
          resolve({
            login: {
              token: response.data.Result.Token,
              user: response.data.Result.Data
            }
          })
        } else reject(response)
      }).catch(reject)
    })
  }

  getApiVersion () {
    let result = ''
    const promise = this._get('version/', {})
    // anything to do with the data before returning it? Otherwise remove the code below and return the promise immediately.
    return promise
      .then(response => {
        if (response.status === 200) {
          if (response.bodyText) {
            const data = JSON.parse(response.bodyText)
            if (data.Result) {
              if (data.Result.APIVersion) { result += 'API Version: ' + data.Result.APIVersion }
              if (data.Result.DBVersion) { result += ', DB Version: ' + data.Result.DBVersion }
            }
          }
        }
        return result
      })
      .catch(() => {
        return 'error'
      })
  }

  checkEmail (email) {
    const postData = {
      email: String(email).trim()
    }

    return new Promise((resolve, reject) => {
      const route = Config.FPDE_API_URL + 'email/check'

      axios({
        url: route,
        data: postData,
        method: 'POST'
      }).then(response => {
        if (response.status === 200) {
          resolve(response.data)
        } else reject(response)
      }).catch(reject)
    })
  }

  needMoreTime (data) {
    const postData = 'data=' + String(data).trim()

    return new Promise((resolve, reject) => {
      const route = Config.FPDE_API_URL + 'email/moretime'

      axios({
        url: route,
        data: postData,
        method: 'POST'
      }).then(response => {
        if (response.status === 200) {
          resolve(response.data)
        } else reject(response)
      }).catch(reject)
    })
  }

  scheduleMBMembershipViewMails (brandID, contactID) {
    const postData = 'brandid=' + brandID + '&contactid=' + contactID

    return new Promise((resolve, reject) => {
      const route = Config.FPDE_API_URL + 'email/scheduleMBMembershipViewMails'
      const headers = {
        Authorization: this.getToken()
      }

      axios({
        headers,
        url: route,
        data: postData,
        method: 'POST'
      }).then(response => {
        if (response.status === 200) {
          resolve(response.data)
        } else reject(response)
      }).catch(reject)
    })
  }

  scheduleRequiresFieldsMails (brandID, contactID) {
    const postData = 'brandid=' + brandID + '&contactid=' + contactID

    return new Promise((resolve, reject) => {
      const route = Config.FPDE_API_URL + 'email/requiredfields'
      const headers = {
        Authorization: this.getToken()
      }

      axios({
        headers,
        url: route,
        data: postData,
        method: 'POST'
      }).then(response => {
        if (response.status === 200) {
          resolve(response.data)
        } else reject(response)
      }).catch(reject)
    })
  }

  unsubscribeMail (mailData) {
    const postData = 'data=' + mailData

    return new Promise((resolve, reject) => {
      const route = Config.FPDE_API_URL + 'email/unsubscribe'
      const headers = {
        Authorization: this.getToken()
      }

      axios({
        headers,
        url: route,
        data: postData,
        method: 'POST'
      }).then(response => {
        if (response.status === 200) {
          resolve(response.data)
        } else reject(response)
      }).catch(reject)
    })
  }

  getGeoDataByZipCodes (postData) {
    return new Promise((resolve, reject) => {
      const route = Config.FPDE_API_URL + 'geodb/zip'

      axios({
        url: route,
        data: postData,
        method: 'POST'
      }).then(response => {
        if (response.status === 200) {
          resolve(response.data)
        } else reject(response)
      }).catch(reject)
    })
  }
}
